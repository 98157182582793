<template>
  <div>

    <!-- #region::Searcher and action button -->
    <BasicListFilters
      title="Mayoristas"
      new-item-button-label="Nuevo mayorista"
      searcher-placeholder="Buscar mayorista"
      :show-create-model="canManageWholesalerUsers"
      @on:filter="handleFilter"
      @on:click="$router.push({ name: 'new-wholesaler' })"
    />
    <!-- #endregion::Searcher and action button -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingWholesalers">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Wholesalers list table -->
          <b-table
            v-if="availableWholesalers"
            :items="wholesalers"
            :fields="columns"
            responsive
            class="my-0"
          >
            <!-- #region:Virtual column for index -->
            <template #cell(No)="data">
              {{ indexFrom + data.index }}
            </template>
            <!-- #endregion:Virtual column for index -->

            <template #cell(Name)="{ item }">
              <router-link
                :to="{ name: 'detail-wholesaler', params: { id: item.IdUser }}"
              ><span class="text-nowrap">{{ item.Name }}</span>
              </router-link>
            </template>

            <template #cell(Company)="{ item }">
              <span class="text-nowrap">{{ item.Company }}</span>
            </template>

            <template #cell(discount)="{ item }">
              {{ item.Discount }}%
            </template>

            <template #cell(quotes)="{ item }">
              {{ item.quotes | placeholder('Sin especificar') }}
            </template>

            <template #cell(orders)="{ item }">
              {{ item.orders | placeholder('Sin especificar') }}
            </template>

            <template #cell(quotesRequest)="{ item }">
              {{ item.quotesRequest | placeholder('Sin especificar') }}
            </template>

            <!-- #region::A virtual column for actions -->
            <template #cell(actions)="data">

              <div class="d-flex justify-content-between">

                <!-- #region::Delete wholesaler button -->
                <b-button
                  v-b-tooltip.hover.top="'Eliminar'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  :disabled="!canManageWholesalerUsers"
                  @click="onDeleteWholesaler(data)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
                <!-- #endregion::Delete wholesaler button -->

                <!-- #region::Edit wholesaler button -->
                <b-button
                  v-b-tooltip.hover.top="'Editar'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  :disabled="!canManageWholesalerUsers"
                  :to="{ name: 'update-wholesaler', params: { id: data.item.IdUser }}"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
              <!-- #endregion::Edit wholesaler button -->

              </div>

            </template>
            <!-- #endregion::A virtual column for actions -->

          </b-table>
          <!-- #endregion::Wholesalers list table -->

          <!-- #region::Alert when no circuits are available -->
          <b-alert
            v-if="!availableWholesalers && !isLoadingWholesalers"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron mayoristas.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no circuits are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableWholesalers"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalWholesalers"
    />
    <!-- #endregion end::Pagination & items per list -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  VBTooltip, VBToggle, BSkeleton, BCard, BCardText, BTable, BButton, BAlert,
} from 'bootstrap-vue'

import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import BasicListFilters from '@/components/tables/BasicListFilters.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { adminLevel, ADMIN_USER_LEVELS } from '@/helpers/UserLevel'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCard,
    BTable,
    BAlert,
    BButton,
    BSkeleton,
    BCardText,
    BasicPaginator,
    BasicListFilters,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      lastPage: 0,
      indexFrom: 0,
      totalWholesalers: 0,
      columns: [
        // A virtual column that doesn't exist in items
        {
          key: 'No',
          label: 'No.',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Name',
          label: 'Nombre',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Company',
          label: 'Empresa',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'email',
          label: 'Correo electrónico',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'Phone',
          label: 'Teléfono',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Discount',
          label: 'Descuento',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'quotes',
          label: 'No. Cotizaciones',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'orders',
          label: 'No. Órdenes',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'quotesRequest',
          label: 'No. Solicitudes',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
      ],
      wholesalers: [],
      isLoadingWholesalers: false,

      adminLevel,
      ADMIN_USER_LEVELS,
    }
  },
  computed: {
    ...mapGetters({
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
    }),
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    availableWholesalers() {
      return this.totalWholesalers > 0
    },

    /**
     * Indica si el usuario puede gestionar usuarios mayoristas
     */
    canManageWholesalerUsers() {
      return this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
  },
  async created() {
    await this.loadWholesalersList()
    this.verifyContenList()
  },
  methods: {
    ...mapActions({
      loadWholesalers: 'wholesalers/loadWholesalers',
      deleteWholesaler: 'wholesalers/deleteWholesaler',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Atiende el evento filtrado del componente de filtros
    * @param {Object} filter - Objeto con el tipo de filtro y el valor
    */
    async handleFilter(filter) {
      this.filteredSearchTerm = filter.value
      this.filteredCurrentPage = 1

      // eslint-disable-next-line no-unused-expressions
      this.$refs['basic-paginator']?.resetCurrentPage()
      await this.loadWholesalersList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      this.loadWholesalersList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.wholesalers.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableWholesalers) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadWholesalersList()
        }
      }
    },

    /**
    * Carga de lista de usuarios mayoristas
    *
    * @summary Atiende la carga de la lista de usuarios mayoristas y establece
    * los valores de la paginación
    * @exception Muestra un mensaje de error en caso de que la petición
    * al servidor falle
    */
    async loadWholesalersList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingWholesalers = true

        const response = await this.loadWholesalers(this.loadParams())
        this.wholesalers = response.data.data.users.data
        this.totalWholesalers = response.data.data.users.total
        this.indexFrom = response.data.data.users.from
        this.lastPage = response.data.data.users.last_page

        this.isLoadingWholesalers = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Eliminación de usuario mayorista
    *
    * @summary Confirmación de eliminación de usuario mayorista y recarga la lista
    * @param {Ojbject} item - Objeto con la información del usuario mayorista
    * @exception Muestra un mensaje de error en caso de que la petición al servidor falle
    */
    async onDeleteWholesaler({ item }) {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La información del mayorista será eliminada pero se conservarán las órdenes y cotizaciones!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        try {
          await this.deleteWholesaler(item.IdUser)
          await this.loadWholesalersList()
          await this.verifyContenList()

          this.$swal.fire('Eliminado', '¡El mayorista ha sido eliminado correctamente!', 'success')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', error.response.data.message, 'danger')
        }
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        perPage: this.filteredPerPage,
        resource: 'wholesalers',
        searchTerm: this.filteredSearchTerm,
        currentPage: this.filteredCurrentPage,
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
