<template>
  <div>

    <!-- #region begin:title -->
    <b-form-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <h3>{{ title }}</h3>
      </b-col>
    </b-form-row>
    <!-- #endregion end:title -->

    <!-- #region begin:: List options -->
    <b-form-row>

      <!-- #region begin::Searcher -->
      <b-col md="4">
        <BasicSearcher
          ref="basic-searcher"
          :callback="handleSearch"
          :placeholder="searcherPlaceholder"
        />
      </b-col>
      <!-- #endregion end::Searcher -->

      <!-- #region begin::New cicuit button -->
      <b-col
        v-if="showCreateModel"
        cols="12"
        md="auto"
        class="ml-auto"
      ><b-button
        variant="principal-btn"
        class="my-1 my-lg-0 w-100 principal-btn"
        @click="$emit('on:click')"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ newItemButtonLabel }}</span>
      </b-button></b-col>
      <!-- #endregion end::New cicuit button -->

      <!-- #region begin::Download CSV button -->
      <b-col
        v-if="showDownloadFile"
        cols="12"
        md="auto"
      ><b-button
        variant="flat-success"
        class="w-100 csv-btn"
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        <span class="align-middle">Descargar CSV</span>
      </b-button></b-col>
      <!-- #endregion end::Download CSV button -->

    </b-form-row>
    <!-- #endregion end:: List options -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BButton, BCol, BFormRow,
} from 'bootstrap-vue'

import BasicSearcher from '@/components/forms/BasicSearcher.vue'

export default {
  components: {
    BCol,
    BButton,
    BFormRow,
    BasicSearcher,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    searcherPlaceholder: {
      type: String,
      required: true,
    },
    newItemButtonLabel: {
      type: String,
      required: true,
    },
    showDownloadFile: {
      type: Boolean,
      default: false,
    },
    showCreateModel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
    }),
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
    },
  },
  mounted() {
    // Carga el término de búsqueda del store de productos
    if (this.filteredSearchTerm) {
      this.$refs['basic-searcher'].loadSearchTerm(this.filteredSearchTerm)
    }
  },
  methods: {
    /**
    * Evento del buscador básico
    * @summary Emite un evento con el término de búsqueda
    * @param {String} searchTerm - Término de búsqueda
    */
    handleSearch(searchTerm) {
      this.$emit('on:filter', { type: 'search', value: searchTerm })
    },
  },
}
</script>
