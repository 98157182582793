var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicListFilters',{attrs:{"title":"Mayoristas","new-item-button-label":"Nuevo mayorista","searcher-placeholder":"Buscar mayorista","show-create-model":_vm.canManageWholesalerUsers},on:{"on:filter":_vm.handleFilter,"on:click":function($event){return _vm.$router.push({ name: 'new-wholesaler' })}}}),(_vm.isLoadingWholesalers)?[_c('b-skeleton',{staticClass:"mt-1",attrs:{"animation":"wave","width":"100%","height":"430px"}})]:[_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[(_vm.availableWholesalers)?_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.wholesalers,"fields":_vm.columns,"responsive":""},scopedSlots:_vm._u([{key:"cell(No)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.indexFrom + data.index)+" ")]}},{key:"cell(Name)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'detail-wholesaler', params: { id: item.IdUser }}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Name))])])]}},{key:"cell(Company)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Company))])]}},{key:"cell(discount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Discount)+"% ")]}},{key:"cell(quotes)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.quotes,'Sin especificar'))+" ")]}},{key:"cell(orders)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.orders,'Sin especificar'))+" ")]}},{key:"cell(quotesRequest)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.quotesRequest,'Sin especificar'))+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Eliminar'),expression:"'Eliminar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary","disabled":!_vm.canManageWholesalerUsers},on:{"click":function($event){return _vm.onDeleteWholesaler(data)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary","disabled":!_vm.canManageWholesalerUsers,"to":{ name: 'update-wholesaler', params: { id: data.item.IdUser }}}},[_c('feather-icon',{attrs:{"icon":"Edit3Icon"}})],1)],1)]}}],null,false,1585693653)}):_vm._e(),(!_vm.availableWholesalers && !_vm.isLoadingWholesalers)?_c('b-alert',{staticClass:"my-0",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se encontraron mayoristas.")])])]):_vm._e()],1)],1)],(_vm.availableWholesalers)?_c('BasicPaginator',{ref:"basic-paginator",staticClass:"mt-2",attrs:{"callback":_vm.handleChangePagination,"total-rows":_vm.totalWholesalers}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }